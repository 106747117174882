import React, { Component } from 'react'
import axios from 'axios';
import CommonVariables from './CommonVariables'

export default class Login extends Component {
  state = {
    username: '',
    password: ''
  }
  handleFormSubmit(event) {
    event.preventDefault();
    let formData = new FormData();
    formData.append('action', 'login')
    formData.append('email', this.state.username)
    formData.append('password', this.state.password)

    axios({
      method: 'post',
      url: CommonVariables.ApiUrl,
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(function (response) {
        //handle success
        console.log(response)
        if (response.data.rescode === 1) {
          sessionStorage.setItem("userdata", JSON.stringify(response.data.data));
          window.location.href = "/";
          //alert(response.data.msg);
        } else {
          alert(response.data.resmsg);
        }

      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }
  render() {

    return (
      <div className="bg-color login">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-sm-3"></div>
            <div className="col-xl-4 col-sm-6">
              <div className="box bg-white margintop_230 loginbox"> <img src="assets/img/isp-rgb-dark.png" /><br />
                <div className="row">
                  <div className="col-xl-9 col-sm-12 pr-1">
                    <input
                      type="email"
                      className="form-control"
                      id="username"
                      name="username"
                      value={this.state.username}
                      onChange={e => this.setState({ username: e.target.value })}
                      placeholder="enter your email"
                    />
                  </div>
                  <div className="col-xl-9 col-sm-12 pr-1">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={e => this.setState({ password: e.target.value })}
                      placeholder="enter your password"
                    />
                  </div>
                  <div className="col-xl-3 col-sm-12 pl-1">
                    <input className="btn btn-block btn-success" type="submit" onClick={e => this.handleFormSubmit(e)} value="Go" />

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-3"></div>
          </div>
        </div>
      </div>
    )
  }
}
